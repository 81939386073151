import React, { useRef, useEffect, useState, useCallback } from 'react';
import { Calendar } from 'react-calendar';
import coverImg from '../../utils/cover-img.jpg';
import Address from '../address/Address';
import './checkout.css';
import 'react-calendar/dist/Calendar.css';
import api from '../../api/api';
import cod from '../../utils/ic_cod.svg';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import { Link, useNavigate } from 'react-router-dom';
import { motion } from 'framer-motion';
import Modal from 'react-bootstrap/Modal';
// import { Modal } from 'antd';

//lottie animation JSONs
import Lottie from 'lottie-react';
import animate1 from '../../utils/order_placed_back_animation.json';
import animate2 from '../../utils/order_success_tick_animation.json';

// import CheckoutForm from './CheckoutForm'

import Loader from '../loader/Loader';
import Promo from '../cart/Promo';
import { useTranslation } from 'react-i18next';
import { setCart, setCartCheckout, setCartProducts, setCartPromo, setCartSubTotal, setWallet } from '../../model/reducer/cartReducer';
import { AiOutlineCloseCircle } from 'react-icons/ai';
import { PiWallet } from "react-icons/pi";
import { deductUserBalance } from '../../model/reducer/authReducer';
import { ValidateNoInternet } from '../../utils/NoInternetValidator';
import { MdSignalWifiConnectedNoInternet0 } from 'react-icons/md';
import { formatDate } from '../../utils/formatDate';
import { setPromoCode } from '../../model/reducer/promoReducer';
import { IoIosWarning } from "react-icons/io";
import Location from '../location/Location';


const Checkout = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const city = useSelector(state => state.city);
    const cart = useSelector(state => (state.cart));
    const address = useSelector((state) => state.address);
    const user = useSelector(state => (state.user));
    const setting = useSelector(state => (state.setting));
    const promoCode = useSelector(state => state.promoCode);


    const [codAllow, setCodAllow] = useState(0);
    const [totalPayment, setTotalPayment] = useState(null);
    const [order, setOrder] = useState(false);
    const [isPromoApplied, setIsPromoApplied] = useState(false);
    const [IsOrderPlaced, setIsOrderPlaced] = useState(false);
    const [expectedDate, setexpectedDate] = useState(null);
    const [expectedTime, setexpectedTime] = useState();
    const [timeslots, settimeslots] = useState(null);
    const [selectedAddress, setselectedAddress] = useState(null);
    const today = new Date();

    const [orderID, setOrderID] = useState(0);
    const [loadingPlaceOrder, setloadingPlaceOrder] = useState(false);

    const [show, setShow] = useState(false);
    const [showPromoOffcanvas, setShowPromoOffcanvas] = useState(false);
    const [isLoader, setisLoader] = useState(false);
    const [isNetworkError, setIsNetworkError] = useState(false);
    const [orderNote, setOrderNote] = useState("");
    const [cartError, setCartError] = useState("")
    const [isLocationPresent, setisLocationPresent] = useState(false);
    const [locModal, setLocModal] = useState(false);
    const [bodyScroll, setBodyScroll] = useState(false)

    useEffect(() => {
        fetchCart();

        api.getCartSeller(user?.jwtToken, city.city.latitude, city.city.longitude, 1)
            .then(res => res.json())
            .then(result => {
                setCodAllow(result?.data?.cod_allowed);
            })
            .catch(error => console.log(error));
        fetchTimeSlot();
    }, []);

    useEffect(() => {
        fetchCart();
    }, [locModal])

    const fetchCart = async () => {
        api.getCart(user?.jwtToken, city.city.latitude, city.city.longitude, 1)
            .then(response => response.json())
            .then(result => {
                if (result.status === 1) {
                    dispatch(setCartCheckout({ data: result.data }));

                    if (promoCode?.isPromoCode === true) {
                        setTotalPayment(result.data.total_amount - promoCode?.discount);
                    } else if (cart?.promo_code) {
                        dispatch(setPromoCode({
                            code: cart.promo_code,
                            discount: cart.promo_code.discount,
                            isPromoCode: true
                        }));
                        setTotalPayment(result.data.total_amount - cart?.promo_code?.discount);
                    } else {
                        setTotalPayment(result.data.total_amount);
                    }
                    setCartError("")
                } else if (result.status === 0) {
                    setCartError(result?.message)
                }
            })
            .catch(error => {
                const isNoInternet = ValidateNoInternet(error);
                if (isNoInternet) {
                    setIsNetworkError(isNoInternet);
                };
            });
    }

    useEffect(() => {
        if (address?.selected_address?.latitude && address?.selected_address?.longitude)
            api.getCart(user?.jwtToken, address?.selected_address?.latitude, address?.selected_address?.longitude, 1)
                .then(response => response.json())
                .then(result => {
                    if (result.status === 1) {
                        setCodAllow(result?.data?.cod_allowed);
                        dispatch(setCartCheckout({ data: result.data }));
                        if (promoCode?.isPromoCode === true) {
                            setTotalPayment(result.data.total_amount - promoCode?.discount);
                        } else {
                            setTotalPayment(result.data.total_amount);
                        }
                    }
                })
                .catch(error => console.log(error));
    }, [address?.selected_address]);


    const checkLastOrderTime = (lastTime) => {
        const currentTime = expectedDate ? expectedDate : new Date();
        if (currentTime > new Date()) {
            return true;
        }
        const hours = lastTime.split(':')[0];
        const minutes = lastTime.split(':')[1];
        const seconds = lastTime.split(':')[2];

        const lastOrderTime = new Date();

        lastOrderTime.setHours(hours);
        lastOrderTime.setMinutes(minutes);
        lastOrderTime.setSeconds(seconds);


        return currentTime <= lastOrderTime;

    };


    const fetchTimeSlot = () => {
        api.fetchTimeSlot()
            .then(response => response.json())
            .then(result => {
                if (result.status === 1) {
                    if (result?.data?.time_slots_is_enabled == "false") {
                        // toast.error(t("timeslots_not_enabled"));
                    }
                    settimeslots(result.data);
                    setexpectedTime(result?.data?.time_slots.filter((element) => checkLastOrderTime(element?.last_order_time))[0]);
                }
            })
            .catch(error => console.log(error));

    };

    // Filter the time slots based on last_order_time
    useEffect(() => {
        const currentDateTime = new Date();
        setexpectedDate(new Date(currentDateTime.setDate(currentDateTime.getDate() + (Number(timeslots?.time_slots_delivery_starts_from) - 1))));
    }, [timeslots]);

    useEffect(() => {
        setexpectedTime(timeslots?.time_slots.filter((element) => checkLastOrderTime(element?.last_order_time))[0]);
    }, [expectedDate]);


    const handleModal = () => {
        setLocModal(true);
        setBodyScroll(true);
    };

    useEffect(() => {
        setTotalPayment(cart?.checkout?.total_amount);
    }, [cart?.is_wallet_checked]);

    const HandlePlaceOrder = async (e) => {
        if (!address.address) {
            toast.error(t("please_select_address"));
        } else if (address.selected_address === null) {
            toast.error("Please Select a Default Delivery Address");
        } else {
            setloadingPlaceOrder(true);
            // place order
            await api.placeOrder(user?.jwtToken, cart.checkout.product_variant_id, cart.checkout.quantity, cart.checkout.sub_total, totalPayment, address.selected_address.id, cart.promo_code?.promo_code_id, orderNote)
                .then(response => response.json())
                .then(async (result) => {
                    setisLoader(false);
                    if (result.status === 1) {
                        setOrderNote("");
                        toast.success("Order Successfully Placed!");
                        setloadingPlaceOrder(false);
                        dispatch(setCartPromo({ data: null }));
                        dispatch(setCartProducts({ data: [] }));
                        dispatch(setCartSubTotal({ data: 0 }));
                        setIsOrderPlaced(true);
                        setShow(true);
                    } else {
                        toast.error(result.message);
                        setloadingPlaceOrder(false);
                        setOrderNote("");
                    }
                    dispatch(setPromoCode({
                        code: null,
                        discount: 0,
                        isPromoCode: false
                    }));
                })
                .catch(error => {
                    setisLoader(false);
                    setloadingPlaceOrder(false);
                    console.log(error);
                });
        }
    };
    const handleClose = () => {
        setisLoader(true);
        api.removeCart(user?.jwtToken).then(response => response.json())
            .then(async (result) => {
                if (result.status === 1) {
                    dispatch(setCart({ data: null }));
                    dispatch(setCartCheckout({ data: null }));
                }
            });
        setShow(false);
        navigate('/');
    };


    useEffect(() => {
        if (IsOrderPlaced) {
            setShow(true);
            setTimeout(async () => {
                handleClose();
            }, 5000);
        }
    }, [IsOrderPlaced]);

    const { t } = useTranslation();

    const placeHolderImage = (e) => {
        e.target.src = setting.setting?.web_logo;
    };

    const current = new Date();
    return (
        <>
            {!isNetworkError ?
                <>
                    <section id='checkout'>
                        {IsOrderPlaced ?
                            <>
                                <Modal
                                    show={show}
                                    onHide={handleClose}
                                    backdrop="static"
                                    keyboard={true}
                                    className='success_modal'
                                    dialogClassName='successModalDialog'>
                                    <Lottie className='lottie-content' animationData={animate1} loop={true}></Lottie>
                                    <Modal.Header closeButton className='flex-column-reverse success_header'>
                                        <Modal.Title>
                                            <Lottie animationData={animate2} loop={false} className='lottie-tick'></Lottie>
                                        </Modal.Title>
                                    </Modal.Header>
                                    <Modal.Body className='success_body d-flex flex-column justify-content-center align-items-center'>
                                        <div>
                                            <p className='order-success-desc'>{t("order_placed_description")}</p>
                                        </div>
                                        <button onClick={handleClose} className='checkout_btn'>
                                            {t("go_to_home")}
                                        </button>
                                    </Modal.Body>
                                </Modal>
                            </>
                            : null}

                        <div className='cover'>
                            <img src={coverImg} onError={placeHolderImage} className='img-fluid' alt="cover"></img>
                            <div className='title'>
                                <h3>{t("checkout")}</h3>
                                <span><Link to="/" className="text-white text-decoration-none">{t("home")} / </Link> </span><span className='active'>{t("checkout")}</span>
                            </div>
                        </div>


                        {!setting.payment_setting === null && !expectedTime === null
                            ? (
                                <Loader screen='full' />
                            )
                            : (
                                <>
                                    <div className='checkout-container container'>

                                        <div className='checkout-util-container col-lg-9'>
                                            <div className='billibg-address-wrapper checkout-component'>
                                                <span className='heading'>{t("billing_details")}</span>

                                                <Address setselectedAddress={setselectedAddress} selectedAddress={selectedAddress} />
                                            </div>
                                        </div>

                                        <div className='order-container'>
                                            <div className='checkout-component order-instructions-wrapper'>
                                                <div className='heading'>{t("order_note")}</div>
                                                <div className='order-instruction-body'>
                                                    <textarea
                                                        rows={4}
                                                        cols={5}
                                                        value={orderNote}
                                                        className='order-instructions-input'
                                                        placeholder={`${t("order_note_hint")}`}
                                                        onChange={(e) => setOrderNote(e.target.value)}
                                                        maxLength={191}
                                                    />
                                                </div>
                                            </div>

                                            <div className='order-summary-wrapper checkout-component'>

                                                <div className="order-bill">
                                                    <div className='heading'>{t("order_summary")}</div>

                                                    <div className='order-details'>
                                                        {cart.checkout === null || user.user === null
                                                            ? (
                                                                <Loader screen='full' />
                                                            )
                                                            : (

                                                                <div className='summary'>
                                                                    <div className='d-flex justify-content-between'>
                                                                        <span>{t("sub_total")}</span>
                                                                        <div className='d-flex align-items-center'>

                                                                            <span>{setting.setting && setting.setting.currency}   {(cart?.checkout?.sub_total)?.toFixed(setting.setting && setting.setting.decimal_point)}</span>
                                                                        </div>
                                                                    </div>

                                                                    <div className='d-flex justify-content-between'>
                                                                        <span>{t("delivery_charge")}</span>
                                                                        <div className='d-flex align-items-center'>

                                                                            <span>{setting.setting && setting.setting.currency}  {(cart?.checkout?.delivery_charge?.total_delivery_charge)?.toFixed(setting.setting && setting.setting.decimal_point)}</span>
                                                                        </div>
                                                                    </div>

                                                                    {promoCode.code !== null && <>
                                                                        <div className='d-flex justify-content-between'>
                                                                            <span>{t("discount")}</span>
                                                                            <div className='d-flex align-items-center'>
                                                                                <span>- {setting.setting && setting.setting.currency}    {Number(promoCode.discount)?.toFixed(setting.setting && setting.setting.decimal_point)}</span>
                                                                            </div>
                                                                        </div>
                                                                    </>}

                                                                    <div className='d-flex justify-content-between total'>
                                                                        <span>{t("total")}</span>
                                                                        <div className='d-flex align-items-center total-amount' style={{ color: "var(--secondary-color)" }}>
                                                                            <span>
                                                                                {setting.setting && setting.setting.currency}
                                                                                {Number(totalPayment)?.toFixed(setting.setting && setting.setting.decimal_point)}
                                                                            </span>

                                                                        </div>
                                                                    </div>

                                                                    {loadingPlaceOrder
                                                                        ?
                                                                        <Loader screen='full' background='none' content={"Your transaction is being processed.Please don't refresh the page."} />
                                                                        : cartError !== "" ?
                                                                            <div>
                                                                                <p className='checkout-warning-msg'><IoIosWarning size={20} />{cartError}</p>
                                                                            </div> :
                                                                            <div className='button-container'>
                                                                                <motion.button whiletap={{ scale: 0.8 }} type='button' className='checkout' onClick={(e) => { e.preventDefault(); HandlePlaceOrder(); }}>{t("place_order")}</motion.button>
                                                                            </div>
                                                                    }

                                                                </div>)}
                                                    </div>
                                                </div>

                                            </div>
                                        </div>
                                    </div>
                                </>
                            )
                        }


                        <Promo show={showPromoOffcanvas} setShow={setShowPromoOffcanvas} />

                    </section>

                </>
                :
                <div className='d-flex flex-column justify-content-center align-items-center noInternetContainer'>
                    <MdSignalWifiConnectedNoInternet0 />
                    <p>{t("no_internet_connection")}</p>
                </div>
            }
        </>
    );
};

export default Checkout;
