import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    status: 'loading', //fulfill
    address: null,
    selected_address: null
};

export const addressReducer = createSlice({
    name: "address",
    initialState,
    reducers: {
        setAddress: (state, action) => {
            state.address = action.payload.data;
            state.status = "fulfill";
        },
        setSelectedAddress: (state, action) => {
            state.selected_address = action.payload.data;
            state.status = "fulfill";

        }
    }
});

export const {
    setAddress,
    setSelectedAddress
} = addressReducer.actions;



export default addressReducer.reducer;